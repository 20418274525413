import * as React from "react";
import { twMerge } from "tailwind-merge";
import Text, { TextVariant } from "../Text";

interface ISimpleParagraph {
  title: string;
  children: JSX.Element;
  className?: string;
}

const SimpleParagraph: React.FunctionComponent<ISimpleParagraph> = ({
  title,
  children,
  className,
}) => {
  return (
    <div
      className={twMerge(
        "lg:container p-4 lg:py-8 lg:px-0 lg:my-10",
        className
      )}
    >
      <div className="lg:grid lg:grid-cols-9 gap-x-4">
        <div className="self-center lg:col-span-3">
          <Text variant={TextVariant.h1}>{title}</Text>
        </div>
        <div className="mt-4 lg:col-span-6 lg:mt-0">{children}</div>
      </div>
    </div>
  );
};

export default SimpleParagraph;
