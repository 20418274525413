import * as React from "react";
import { twMerge } from "tailwind-merge";

interface Quote {
  picture?: React.ReactNode;
  nameAndTitle: React.ReactNode;
  quote: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const Quote = ({ picture, nameAndTitle, quote, className, onClick }: Quote) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <div
      className={twMerge(
        "flex flex-col p-6 lg:p-8 shadow-team rounded-2xl",
        className
      )}
      onClick={handleClick}
    >
      <div className="flex">
        {picture && <div className="mr-5">{picture}</div>}
        <div className="my-auto text-base lg:text-xl">{nameAndTitle}</div>
      </div>
      <div className="mt-8 text-main">{quote}</div>
    </div>
  );
};

export default Quote;
