import * as React from "react";
import { twMerge } from "tailwind-merge";

interface IText {
  variant: TextVariant;
  className?: string;
  children: React.ReactNode;
}

// Verdana -> Work Sans
// fredoka One -> Poppins

export enum TextVariant {
  h1 = "font-poppins font-light text-2xl lg:text-4.5xl",
  h2 = "invisible lg:visible font-poppins font-medium text-3xl text-primaryDark",
  h3 = "invisible lg:visible font-poppins font-semibold text-2xl leading-9 text-primaryDark",
  h4 = "font-work font-medium text-base lg:text-xl text-primaryDark",
  text1 = "font-work text-xs tracking-wide lg:text-base text-primaryDark",
  text2 = "font-work text-xs italic tracking-wide lg:text-base text-primaryDark",
  text3 = "font-work font-bold text-xs tracking-wide lg:text-base text-primaryDark",
}

const Text: React.FunctionComponent<IText> = ({
  variant,
  className,
  children,
}) => {
  const calulatedClassName = twMerge(variant, className);

  if (
    [TextVariant.text1, TextVariant.text2, TextVariant.text3].includes(variant)
  ) {
  }

  switch (variant) {
    case TextVariant.h1:
      return <h1 className={calulatedClassName}>{children}</h1>;
    case TextVariant.h2:
      return <h2 className={calulatedClassName}>{children}</h2>;
    case TextVariant.h3:
      return <h3 className={calulatedClassName}>{children}</h3>;
    case TextVariant.h4:
      return <h4 className={calulatedClassName}>{children}</h4>;
    default:
      return <p className={calulatedClassName}>{children}</p>;
  }
};

export default Text;
