import * as React from "react";
import Button, { ButtonVariant } from "../Button";
import ContactForm from "../ContactForm";
import Footer from "../Footer";
import Header from "../Header";
import { useLayout } from "../Layout";
import Meta from "../Meta";
import Modal from "../Modal";

interface Layout {
  metaTitle: string;
  metaTitleSuffix?: string;
  banner?: string;
  description?: string;
}

const Layout: React.FC<Layout> = ({
  metaTitle,
  metaTitleSuffix,
  description,
  children,
}) => {
  const [setShowModal, showModal] = useLayout();

  return (
    <div className="font-sans text-main text-primaryDark">
      <Modal
        isOpen={showModal}
        title="Contactez-nous"
        onClose={() => setShowModal(false)}
      >
        <ContactForm onValidate={() => setShowModal(false)} />
      </Modal>
      <Meta
        title={metaTitle}
        titleSuffix={metaTitleSuffix}
        description={description}
      />
      <Header>
        <Button
          className="lg:px-4"
          textClassName="text-xl hover:bg-primaryDark"
          variant={ButtonVariant.primary}
          text="Contact"
          onClick={() => setShowModal(true)}
        />
      </Header>
      {/* content */}
      <div className="mt-16">{children}</div>
      <Footer className="mt-0 lg:mt-0" />
    </div>
  );
};

export default Layout;
