import * as React from "react";
import { twMerge } from "tailwind-merge";
import Text, { TextVariant } from "../Text";

interface Card {
  icon: JSX.Element;
  title: string;
  text: React.ReactNode;
  className?: string;
}

const Card: React.FunctionComponent<Card> = ({
  icon,
  title,
  text,
  className,
}) => {
  return (
    <div
      className={twMerge(
        "rounded-[32px] bg-greyUltraLight p-4 lg:p-8",
        className
      )}
    >
      <div className="flex flex-row items-center">
        <div className="w-12 h-12 lg:w-20 lg:h-20">{icon}</div>
        <Text variant={TextVariant.h4} className="ml-4">
          {title}
        </Text>
      </div>
      <Text variant={TextVariant.text1} className="mt-4">
        {text}
      </Text>
    </div>
  );
};

export default Card;
