import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button, { ButtonVariant } from "../../components/Button";
import { useLayout } from "../../components/Layout/layout-context";
import Quote from "../../components/Quote";
import Text, { TextVariant } from "../../components/Text";
import Layout from "../../components/UseCase/Layout";
import SimpleParagraph from "../../components/UseCase/SimpleParagraph";
import UseCaseCard from "../../components/UseCase/Card";
import SimpleList from "../../components/UseCase/SimpleList";
import LogoCard from "../../components/UseCase/LogoCard";

const InventivIt = () => {
  const [setShowModal] = useLayout();

  const circleClassName = "relative flex mx-2 lg:mx-0";
  const circleContentClassName =
    "z-10 h-12 w-12 lg:h-[88px] lg:w-[88px] flex items-center justify-center m-2 lg:m-4 bg-white rounded-full lg:mt-4";

  return (
    <Layout
      metaTitle="Cas client - Inventiv IT"
      description="Découvrez comment nous avons accompagné Inventiv IT dans la mise en en place d’une stratégie qui répond à leurs enjeux actuels"
    >
      {/* header */}
      <div className="relative flex overflow-hidden h-[126px] lg:h-[410px]">
        <div className="absolute right-0 w-[288px] h-[126px] lg:w-[944px] lg:h-[410px]">
          <StaticImage
            src="../../images/photos/inventiv-it-header.png"
            objectFit="cover"
            alt="image d'un cadeau"
          />
        </div>
        <div className="z-20 my-auto w-40 h-[95px] lg:w-[525px] lg:h-[312px] bg-white ml-4 lg:ml-40">
          <StaticImage
            src="../../images/logos/inventiv-it.png"
            alt="logo cadeau maestro"
            className="mx-auto my-auto"
          />
        </div>
      </div>
      {/* title*/}
      <SimpleParagraph
        title="Innover pour un monde meilleur"
        children={
          <>
            <Text variant={TextVariant.text1}>
              « Nous pouvons entreprendre et travailler autrement. <br />
              C’est avec cette conviction que nous avons mis en place notre
              société participative en 2008.
            </Text>
            <Text variant={TextVariant.text1} className="mt-4">
              Nous partageons cette vision avec les inventifs aujourd’hui :
              mettre en commun le meilleur de nos compétences pour contribuer à
              rendre le monde meilleur et mettre les technologies au service de
              l’humain pour améliorer les conditions de vie de chacun. »
            </Text>
            <Text variant={TextVariant.text1} className="text-right">
              Redouane Labdoui – CEO
            </Text>
          </>
        }
        className="mt-4 lg:mt-10"
      />

      {/* picture */}
      <div className="lg:mt-10 bg-secondary">
        <div className="flex lg:container lg:px-0">
          <StaticImage
            src="../../images/photos/inventiv-it-equipe.png"
            alt="image cadeau maestro"
            className="lg:w-full"
          />
        </div>
      </div>

      {/* objectifs */}
      <div className="p-4 mx-auto lg:container lg:py-8 lg:mt-10 lg:px-0">
        <div className="lg:grid lg:grid-cols-9">
          <div className="self-center lg:col-span-3 lg:row-span-4">
            <Text variant={TextVariant.h1}>Objectifs du client</Text>
          </div>
          <div className="flex flex-col gap-2 mt-4 lg:gap-8 lg:col-span-6 lg:mt-0">
            <div>
              <div className="flex items-center">
                <div className="w-12 h-12 lg:w-20 lg:h-20">
                  <StaticImage
                    alt="donut nappage"
                    src="../../images/icons/illustration-p1.png"
                  />
                </div>
                <Text variant={TextVariant.text1} className="ml-2">
                  <span className="font-bold">Clarifier</span> leur
                  positionnement
                </Text>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <div className="w-12 h-12 lg:w-20 lg:h-20">
                  <StaticImage
                    alt="donut toping"
                    src="../../images/icons/illustration-p2.png"
                  />
                </div>
                <Text variant={TextVariant.text1} className="ml-2">
                  Définir une <span className="font-bold">méthodologie</span>{" "}
                  commune à toutes leurs offres
                </Text>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <div className="w-12 h-12 lg:w-20 lg:h-20">
                  <StaticImage
                    alt="donut magie"
                    src="../../images/icons/illustration-p3.png"
                  />
                </div>
                <Text variant={TextVariant.text1} className="ml-2">
                  Avoir une visibilité sur l'impact de ces optimisations avec
                </Text>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <div className="w-12 h-12 lg:w-20 lg:h-20">
                  <StaticImage
                    alt="médaille"
                    src="../../images/icons/illustration-p4.png"
                  />
                </div>
                <Text variant={TextVariant.text1} className="ml-2">
                  Être pilotés par des{" "}
                  <span className="font-bold">plans d’actions</span> priorisés
                  pour les 6 mois à venir
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -- Accompagnement -- */}
      <div className="p-4 mt-10 bg-backgroundD1 lg:p-0">
        <div className="lg:container lg:mx-auto lg:px-0 lg:py-4">
          <div>
            <Text variant={TextVariant.h1} className="text-greyUltraLight">
              Un accompagnement sur mesure
            </Text>
            <Text variant={TextVariant.h4} className="mt-2 text-greyUltraLight">
              Afin de répondre à cette demande de façon personnalisée, nous
              avons mis en place des actions individuelles et collaboratives.
            </Text>
          </div>
          {/* avec le client */}
          <div className="gap-4 mt-8 lg:grid lg:grid-cols-9 lg:grid-rows-2">
            <div className="self-center lg:col-span-3 lg:row-span-2">
              <div className="flex flex-row items-center">
                <div className="w-12 h-12 lg:hidden">
                  <StaticImage
                    src="../../images/icons/client-face.png"
                    alt="client face icon"
                  />
                </div>
                <Text
                  variant={TextVariant.h1}
                  className="ml-8 lg:ml-0 text-greyUltraLight"
                >
                  Avec le client
                </Text>
              </div>
              <div className="justify-center hidden mt-8 lg:flex">
                <div className="w-20 h-20 ">
                  <StaticImage
                    src="../../images/icons/client-face.png"
                    alt="client face icon"
                  />
                </div>
              </div>
              <Text
                variant={TextVariant.text1}
                className="mt-4 text-greyUltraLight lg:mt-8"
              >
                Des ateliers collaboratifs originaux auxquels participent les
                différents membres de l’équipe. Les solutions élaborées sont
                ainsi co-construites grâce au rôle de chacun.
              </Text>
            </div>

            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-6"
              icon={
                <StaticImage
                  src="../../images/icons/castle.png"
                  alt="icone de chateau"
                />
              }
              title="Séminaire de 3 demi-journées"
              text={
                <>
                  Une journée et demi de collaboration fédératrice pour
                  permettant l’alignement des visions au sein de l’équipe avec :
                  <ul className="list-disc list-inside">
                    <li>
                      des travaux en groupes permettant l’expression de chacun
                    </li>
                    <li>une approche transversale aux différents métiers</li>
                    <li>
                      l’optimisation de la complémentarité des rôles de chacun
                    </li>
                  </ul>
                </>
              }
            />
            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-6"
              icon={
                <StaticImage
                  src="../../images/icons/donut-pen.png"
                  alt="crayon donut icone"
                />
              }
              title="Ateliers de Design Thinking"
              text={
                <ul className="list-disc list-inside">
                  <li>
                    Une série d’ateliers collaboratifs pour formuler des axes
                    d’amélioration, les prioriser et les synthétiser en plans
                    d’actions applicables.
                  </li>
                  <li>
                    Un atelier d’identification des valeurs et forces de
                    l’entreprise incarnant son image de marque.
                  </li>
                </ul>
              }
            />
          </div>

          {/* Par l'agence */}
          <div className="gap-4 mt-8 lg:mt-12 lg:grid lg:grid-cols-9 lg:grid-rows-2">
            <div className="self-center lg:hidden">
              <div className="flex flex-row items-center">
                <div className="w-12 h-12">
                  <StaticImage
                    src="../../images/icons/donut-home.png"
                    alt="maison donut icone"
                  />
                </div>
                <Text
                  variant={TextVariant.h1}
                  className="ml-8 text-greyUltraLight"
                >
                  Par l'agence
                </Text>
              </div>

              <Text
                variant={TextVariant.text1}
                className="mt-4 text-greyUltraLight"
              >
                L’équipe Donut Panic concote un programme soigné et ludique pour
                la réussite de votre projet. Résultat ? Des solutions, du
                partage et même quelques bonus !
              </Text>
            </div>
            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-6"
              icon={
                <StaticImage
                  src="../../images/icons/donut-map.png"
                  alt="icone plan avec donut"
                />
              }
              title="Plans d'action"
              text="La synthèse des ateliers réalisés détaille les actions à entreprendre pour améliorer l’offre globale et saisir les opportunités d’évolution. Elle comprend le point de vue des différents membres de l’équipe pour une réalisation des tâches en cohérence avec les responsabilités endossées par chacun."
            />
            <div className="self-center hidden lg:block lg:col-span-3 lg:row-span-2">
              <Text variant={TextVariant.h1} className="text-greyUltraLight">
                Par l'agence
              </Text>
              <div className="flex justify-center mt-8">
                <div className="w-20 h-20">
                  <StaticImage
                    src="../../images/icons/donut-home.png"
                    alt="maison donut icone"
                  />
                </div>
              </div>
              <Text
                variant={TextVariant.text1}
                className="mt-8 text-greyUltraLight"
              >
                L’équipe Donut Panic concote un programme soigné et ludique pour
                la réussite de votre projet. Résultat ? Des solutions, du
                partage et même quelques bonus !
              </Text>
            </div>
            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-3"
              icon={
                <StaticImage
                  src="../../images/icons/map.png"
                  alt="icone de plan"
                />
              }
              title="Tableau de bord"
              text="Un espace partagé complet et détaillé où retrouver toutes les livrables et l’historique de nos échanges."
            />
            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-3"
              icon={
                <StaticImage
                  src="../../images/icons/illustration-p1.png"
                  alt="nappage donut icone"
                />
              }
              title="Suivi"
              text="Accompagnement et présentation des recommandations pour la suite des démarches à entreprendre."
            />
          </div>
        </div>
      </div>

      {/* L'effet Donut Panic */}
      <SimpleList
        title="L'effet Donut Panic"
        children={
          <div className="flex flex-col gap-2 lg:gap-8 lg:col-span-6">
            <div className="flex flex-row items-center">
              <div className="w-12 h-12 lg:w-20 lg:h-20">
                <StaticImage
                  alt="montgolfière donut"
                  src="../../images/icons/donut-balloon.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Une équipe soudée par des objectifs communs qui va dans la même
                direction
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-12 h-12 lg:w-20 lg:h-20">
                <StaticImage
                  alt="cadeau donut"
                  src="../../images/icons/donut-gift.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Des livrables détaillées sur Notion et un board Miro
                collaboratif
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-12 h-12 lg:w-20 lg:h-20">
                <StaticImage
                  alt="coupe donut"
                  src="../../images/icons/donut-cup.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Une efficacité grandissante grâce à des plans d’action priorisés
                validés par tous les collaborateurs
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-12 h-12 lg:w-20 lg:h-20">
                <StaticImage
                  alt="donut yin yang"
                  src="../../images/icons/donut-yinyang.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Une expérience utilisateur optimisée pour des clients et des
                collaborateurs comblés
              </Text>
            </div>
          </div>
        }
        className=""
      />

      {/* chiffres-clefs */}
      <div className="p-4 bg-otherColor3">
        <div className="grid-cols-9 gap-4 lg:container lg:grid lg:px-0">
          <Text variant={TextVariant.h1} className="self-center col-span-3">
            Chiffres clés
          </Text>
          {/* mobile */}
          <div className="flex flex-col gap-2 mt-4 lg:hidden">
            <div className="flex items-center p-2 bg-white rounded-full">
              <div className={circleClassName}>
                <div className="absolute w-full h-full">
                  <StaticImage
                    alt="bg1"
                    src="../../images/photos/circle1.png"
                  />
                </div>
                <Text
                  variant={TextVariant.h4}
                  className={circleContentClassName}
                >
                  23
                </Text>
              </div>
              <Text variant={TextVariant.h4}>heures de séminaire</Text>
            </div>

            <div className="flex items-center p-2 bg-white rounded-full">
              <div className={circleClassName}>
                <div className="absolute w-full h-full">
                  <StaticImage
                    alt="bg5"
                    src="../../images/photos/circle5.png"
                  />
                </div>
                <Text
                  variant={TextVariant.h4}
                  className={circleContentClassName}
                >
                  12
                </Text>
              </div>
              <Text variant={TextVariant.h4}>plans d’action priorisés</Text>
            </div>

            <div className="flex items-center p-2 bg-white rounded-full">
              <div className={circleClassName}>
                <div className="absolute w-full h-full">
                  <StaticImage
                    alt="bg4"
                    src="../../images/photos/circle4.png"
                  />
                </div>
                <Text
                  variant={TextVariant.h4}
                  className={circleContentClassName}
                >
                  23
                </Text>
              </div>
              <Text variant={TextVariant.h4}>collaborateurs inventifs</Text>
            </div>
          </div>
          {/* desktop */}
          <div className="hidden col-span-2 lg:flex bg-white rounded-[32px] flex-col items-center px-4 py-8 gap-4">
            <div className={circleClassName}>
              <div className="absolute w-full h-full">
                <StaticImage alt="bg1" src="../../images/photos/circle1.png" />
              </div>
              <Text variant={TextVariant.h4} className={circleContentClassName}>
                23
              </Text>
            </div>
            <Text variant={TextVariant.h4} className="text-center">
              heures de séminaire
            </Text>
          </div>

          <div className="hidden col-span-2 lg:flex bg-white rounded-[32px] flex-col items-center px-4 py-8 gap-4">
            <div className={circleClassName}>
              <div className="absolute w-full h-full">
                <StaticImage alt="bg5" src="../../images/photos/circle5.png" />
              </div>
              <Text variant={TextVariant.h4} className={circleContentClassName}>
                12
              </Text>
            </div>
            <Text variant={TextVariant.h4} className="text-center">
              plans d'action priorisés
            </Text>
          </div>

          <div className="hidden col-span-2 lg:flex bg-white rounded-[32px] flex-col items-center px-4 py-8 gap-4">
            <div className={circleClassName}>
              <div className="absolute w-full h-full">
                <StaticImage alt="bg4" src="../../images/photos/circle4.png" />
              </div>
              <Text variant={TextVariant.h4} className={circleContentClassName}>
                23
              </Text>
            </div>
            <Text variant={TextVariant.h4} className="text-center">
              collaborateurs inventifs
            </Text>
          </div>
        </div>
      </div>

      {/* L'aventure continue */}
      <SimpleParagraph
        title="L'aventure continue"
        children={
          <Text variant={TextVariant.text1}>
            À ce jour, notre <strong>collaboration</strong> avec Cadeau Maestro
            se poursuit sur cette belle lancée. De{" "}
            <strong>nouvelles missions</strong> nous ont été confiées, signe
            d'un <strong>partenariat</strong> qui, on l'espère, fonctionnera
            encore longtemps.
          </Text>
        }
      />

      {/* témoignage */}
      <div className="p-4 bg-otherColor2">
        <div className="grid-cols-9 gap-4 lg:container lg:grid lg:px-0">
          <Text variant={TextVariant.h1} className="col-span-3 lg:self-center">
            Nos clients témoignent
          </Text>
          <Quote
            className="col-span-6 mt-4 bg-white lg:mt-0"
            picture={
              <StaticImage
                src="../../images/photos/sandra-RANDRIATRIMOMANANA.png"
                alt="photo de Sandra"
                className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
              />
            }
            nameAndTitle={
              <Text variant={TextVariant.h4}>
                Sandra RANDRIATRIMOMANANA
                <br />
                Chef de projet marketing digital à Inventiv’IT
              </Text>
            }
            quote={
              <Text variant={TextVariant.text2}>
                “Quentin est intervenu en tant que Coach Design thinking et nous
                a aidé à cadrer nos réflexions en septembre 2022. Le but était
                de définir les actions pour atteindre nos objectifs chez
                Inventiv IT. Entre autres, nos principaux sujets étaient la mise
                en place de nouvelles offres et l'amélioration de nos process.
                Nous sommes satisfaits de l'accompagnement, que ce soit en
                termes de démarche ou de livrable. Quentin est à l'écoute,
                orienté résultat, il tient bien compte des enjeux, et sa
                capacité d'adaptation est remarquable.”
              </Text>
            }
          />
        </div>
      </div>

      {/* nos engagements */}
      <SimpleList
        title="Nos engagements"
        children={
          <div className="flex flex-col gap-2 lg:gap-8 lg:col-span-6">
            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Uniquement des recommandations <strong>activables</strong>
              </Text>
            </div>

            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Vous inclure dans la conception de votre produit
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                <strong>Transparence</strong> sur les méthodologies utilisées
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Vous faire monter en compétences
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Travailler dans un <strong>esprit collaboratif</strong>
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Prôner le partage et la bonne humeur
              </Text>
            </div>
          </div>
        }
      />

      {/* RDV */}
      <div className="p-4 bg-primary">
        <div className="grid-cols-9 lg:grid lg:container lg:px-0">
          <Text
            variant={TextVariant.h1}
            className="self-center col-span-3 text-greyUltraLight"
          >
            Obtenez un rendez-vous gratuit
          </Text>
          <div className="rounded-[32px] mt-4 lg:p-8 bg-white p-4 col-span-6 lg:mt-0">
            <Text variant={TextVariant.text1}>
              L’aventure vous intéresse ? Vous cherchez une{" "}
              <strong>prestation</strong> ? Rejoignez-nous pour élaborer
              ensemble une <strong>solution personnalisée</strong> qui soit
              adaptée à vos besoins.
            </Text>
            <Button
              text="Contactez-nous"
              variant={ButtonVariant.primary}
              className="mx-auto mt-4 lg:mt-8"
              onClick={() => setShowModal(true)}
            />
          </div>
        </div>
      </div>

      {/* d'autres use cases */}
      <div className="p-4">
        <div className="grid-cols-9 lg:grid lg:container lg:px-0 lg:py-10">
          <Text variant={TextVariant.h1} className="self-center col-span-3">
            D’autres Use cases pour ce type de prestation
          </Text>
          <div className="rounded-[32px] mt-4 col-span-6 lg:mt-0">
            <div className="grid grid-cols-2 gap-4">
              <LogoCard linkTo="/client/cadeau-maestro">
                <StaticImage
                  src="../../images/logos/cadeau-maestro.jpg"
                  alt="logo de cadeau maestro"
                  objectFit="contain"
                  className="h-24"
                />
              </LogoCard>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InventivIt;
