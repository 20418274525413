import * as React from "react";
import { twMerge } from "tailwind-merge";
import Text, { TextVariant } from "../Text";

interface ISimpleList {
  title: string;
  children: JSX.Element;
  className?: string;
}

const SimpleList: React.FunctionComponent<ISimpleList> = ({
  title,
  children,
  className,
}) => {
  return (
    <div className={twMerge("lg:container p-4 lg:py-10 lg:px-0", className)}>
      <div className="my-8 lg:grid lg:grid-cols-9 gap-x-4">
        <div className="lg:col-span-3 lg:self-center">
          <Text variant={TextVariant.h1}>{title}</Text>
        </div>
        <div className="mt-4 lg:col-span-6 lg:mt-0">{children}</div>
      </div>
    </div>
  );
};

export default SimpleList;
