import { Link } from "gatsby";
import * as React from "react";

interface ILogoCard {
  linkTo: string;
  children: JSX.Element;
}

const LogoCard: React.FunctionComponent<ILogoCard> = ({ linkTo, children }) => {
  return (
    <Link to={linkTo}>
      <div className="p-2 shadow-team rounded-2xl h-28 lg:h-36">{children}</div>
    </Link>
  );
};

export default LogoCard;
